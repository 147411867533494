@mixin background-blur {
    content: '';
    z-index: -1;
    width: 100%;
    height: 100%;
    position:absolute; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    filter: blur(3px);  
    transform: scale(1.1);
}

@mixin parallax-after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  
    transform: translateZ(-1px) scale(1.5);
    background-size: 100%;
    z-index: -1;
}