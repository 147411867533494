body.bioBody::before {
  background: url("../img/lgb-sexyeyes-whiteband.jpg") no-repeat center center fixed;
  content: '';
  z-index: -1;
  width: 100%;
  height: 100%;
  position:absolute; 
  top: 80%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px);
  -o-filter: blur(15x);
  -ms-filter: blur(15px);
  filter: blur(25px);
  transform: scale(1.2);
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: .15;
}

@media screen and (max-width: 900px) {
  body.bioBody::before {
    top: 65%;
    right: 30%;
    transform: scale(1.6);
  }
}
.bioContent {
	background: rgba(200, 200, 200, 0.3);

}