body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.wrapper {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 2px;
}
