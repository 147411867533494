@import "../_mixins.scss";
@import "../_colors.scss";

body.listenBody::before {
  background: url("../img/work.jpg") no-repeat center center fixed;
  @include background-blur;
}

@media screen and (max-width: 768px) {
  body.listenBody::before {
    transform: scale(1.5);
  }
}

.section {
  position: relative;
  height: 75vh;
  width: 100%;

  /* For text formatting. */
  align-items: center;
  justify-content: center;
  color: $white;;
  text-shadow: 0 0 1px $white;

  .album {
    height: 300px;
    width: 100%;
    background-color: rgba(0,0,0,.9);
  }
}

.parallax::after {
  @include parallax-after;
}

/* The styling for the static div. */
.static {
/*   background: #ccc;
 */}

.body-background::after {
  background-image: url('../img/lgb-body-red.jpg');
  @include background-blur;
  background-size: contain;
}

.loveletter-background::after {
  background-image: url('../img/lgb-loveletter-cover.jpg');
  @include background-blur;
  background-size: contain;
}

.hollywood-background::after {
  background-image: url('../img/lgb-hollywood-cover.jpg');
  @include background-blur;
}

.how-background::after {
  background-image: url('../img/lgb-how-cover.jpg');
  @include background-blur;
}

.sunshine-background::after {
  background-image: url('../img/lgb-sunshine-cover.jpg');
  @include background-blur;
}

