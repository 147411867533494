@import "../_mixins.scss";
@import "../_colors.scss";

body.lyricsBody::before {
  background: url("../img/manne-flames-reduce.jpg") no-repeat center center fixed;
  @include background-blur;
}

@media screen and (max-width: 768px) {
  body.listenBody::before {
    transform: scale(1.5);
  }
}

.Lyrics {
  margin-top: 40px;

  .spacer {
    height: 60px;
  }
}
.section {
  position: relative;
  width: 100%;
  height: auto;

  /* For text formatting. */
  align-items: center;
  justify-content: center;
  color: $white;
  text-shadow: 0 0 1px $white;

  .collapse:not(.show) {   
    height: 10rem;
    display: block;
    overflow: hidden;
  }

  .collapse.show {
    height: auto;
  }

  .read-more,
  .read-more-hollywood {
    opacity: 1;
    transition: all .3s linear;
    position: absolute;
    text-align: center;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(
       rgba(0, 0, 0, 0) 50%,
       rgba(0, 0, 0, 1) 100%);
  }
  
  .read-more:not(.collapse),
  .read-more-hollywood:not(.collapse) {
    opacity: 0;
    /* If you want to make the clickable area disapear once it is open, change the visibility to hidden */
    /* visibility: hidden; */
  }
  .lyricBox {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: rgba(13.9,0,32.9,.2);

    .body-background {
      background: url('../img/lgb-body-red.jpg');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: top left; 
        }

    .hollywood-background {
      background: url('../img/lgb-hollywood-cover.jpg') no-repeat auto fixed / cover;
      overflow: hidden;
        }

    .lyricContent {
      font-family: 'Lucida', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
      text-transform: uppercase;
      width: 60%;
      margin: auto;
    }

    .lyricButton {
      position: relative;
      z-index: 50;
      margin: 10px auto;
      text-align: center;
    }
  }

}







