@import "../_colors.scss";

.Header {
	background: $header-background;
}

.bannerTitle {
	font-family: 'NeuropolXRg-Regular';
	font-weight: bold;
	font-style: normal;
	text-shadow: 1px 0px 4px #8e8d41, 1px 1px 4px #8e8d41, 0px 1px 4px #8e8d41, 
    -1px 1px 4px #8e8d41, -1px 0px 4px #8e8d41, -1px -1px 4px #8e8d41, 
	0px -1px 4px #8e8d41, 1px -1px 4px #8e8d41, 0px 0px 4px #8e8d41;
	
	svg {
		width: 350px;
	  }
}

.nav-link {
	font-weight: bold;
}

.nav-link:hover {
	cursor: pointer;
}

@media screen and (max-width: 900px) {
	.bannerTitle {
		svg {
			width: 230px;
			text.lgb {
				font-size: 30px;
			}
		}
	}
}