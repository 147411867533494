@import "../_colors.scss";
@import "../_variables.scss";
@import "../_mixins.scss";

body.contactBody::before {
  background: url("../img/gaviota.jpg") no-repeat center center fixed;
  @include background-blur;
}

@media screen and (max-width: 900px) {
  body.contactBody::before {
    transform: scale(1.2)
  }
}

.contactBody {
  .formContainer {
    margin-top: 20px;
    width: 90%;
    background-color: rgba(255,255,255,0.8);
    border-radius: $radius;

    form {
      padding: 10px;

      .recaptcha {
        margin: auto;

      }

      .submitButton {
        margin: 0 auto;
      }
    }
  }
}