@import "_colors.scss";
@import "_variables.scss";
@import "_mixins.scss";

body, html {
          height: 100%;
          overflow: hidden;
    	}

body::before {
  background: url("./img/alligator.jpg") no-repeat center center fixed;
/*  background: url("./img/whiteboots.jpg") no-repeat center center fixed; */
  @include background-blur;
  filter: blur(0);  
}

@media screen and (max-width: 900px) {
  body::before {
    transform: scale(1.5)
  }
}

.services {
  background-color: #ff5bfe;
  opacity: .75;
  border-radius: 15px;
  padding: 4px;
}
.linkIcon {
  margin: 0 10px;
}

.linkIcon:hover {
  opacity: 1.0;
  cursor: pointer;
}

.title-card-wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  border-radius: $radius;
  background-color: $black;
  color: $white;
  /*min-height: calc(395px /2);*/
  background-color: rgba(128,128,128,0.2);
  position: relative;
  align-items: center;

  .title-card-text {
    width: 100%;
    padding: 8px;
    text-align: center;
    color: $black;
  }
}

.home-left {
  background: url('./img/lgb-mannequin-sm.jpg');
  background-size: 100%;
  width: 150px;
  height: calc(395px /2);
  margin: 55px auto 0 auto;
  border-radius: $radius;
}

.home-right {
  background: url('./img/lgb-mannequin-sm.jpg');
  background-size: 100%;
  width: 150px;
  height: calc(395px /2);
  transform: scaleX(-1);
  filter: FlipH;
  margin: 55px auto 0 auto;
  border-radius: $radius;
}

.feature-row {
  margin-top: 20px;
  margin-bottom: 20px;

  .feature-title {
    color: #fff;
    text-align: center;
    padding-top: 6px;    
  }

  .feature-card {
    margin: 26px;
    background-color: rgba(0,0,0,0.6);
  }
}
.feature-title {
  color: #fff;
  text-align: center;
  padding-top: 6px;
  font-weight:bold;
}

.feature-card {
  margin: 26px auto;
  width: 680px;
  background-color: rgba(0,0,0,1);
}

.feature-body {
  background-color: #fff;
}

.twitter-embed {
  margin: 26px;
}
.glow {
  color: #fff;
  text-shadow:
      0 0 7px #fff,
      0 0 10px #fff,
      0 0 21px #fff,
      0 0 42px #0fa,
      0 0 82px #0fa,
      0 0 92px #0fa,
      0 0 102px #0fa,
      0 0 151px #0fa;
}

@media screen and (max-width: 900px) {

  .linkIcon {
    font-size: 48px;
  }

  .feature-card {
    margin: 26px auto;
    width: 340px;
    background-color: rgba(0,0,0,1);
  }

  .feature-body {
    font-size: 1.2em;
  }
}