/* @license
 * MyFonts Webfont Build ID 2505330, 2013-03-14T19:22:16-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Neuropol X Regular by Typodermic
 * URL: http://www.myfonts.com/fonts/typodermic/neuropol-x/neuropol-x/
 * Copyright: (c) 2002-2010 Typodermic Fonts. This font is not freely distributable. Visit typodermic.com for more info.
 * Licensed pageviews: Unlimited
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2505330
 * 
 * © 2013 MyFonts Inc
*/




  
@font-face {font-family: 'NeuropolXRg-Regular';src: url('./webfonts/263A72_0_0.eot');src: url('./webfonts/263A72_0_0.eot?#iefix') format('embedded-opentype'),url('./webfonts/263A72_0_0.woff') format('woff'),url('./webfonts/263A72_0_0.ttf') format('truetype');}
 